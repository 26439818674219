import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import * as CONSTANTS from '../constants';
import GoogleMap from '../components/GoogleMap.js';

const PAGE_TITLE = 'Travel Map';

const RED_MARKER = '/images/maps-red-marker.png';
const BLUE_MARKER = '/images/maps-blue-marker.png';
const PURPLE_MARKER = '/images/maps-purple-marker.png';

function TravelMap() {
  return (
    <div>
      <Helmet>
        <title>
          {PAGE_TITLE} | {CONSTANTS.GLOBAL_NAME}
        </title>
      </Helmet>
      <h3>{PAGE_TITLE}</h3>
      <img src={RED_MARKER} alt="Red Marker" />
      &nbsp;Visited&nbsp;
      <img src={BLUE_MARKER} alt="Blue Marker" />
      &nbsp;Lived&nbsp;
      <img src={PURPLE_MARKER} alt="Purple Marker" />
      &nbsp;Planned
      <br />
      <div className="d-flex justify-content-center" id="spinner">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      <div id="map">
        <GoogleMap />
      </div>
      <div id="countries"></div>
      <div id="territories"></div>
      <div id="states"></div>
      <br />
      <div id="external_links">
        <a href="https://openflights.org/user/PTK8ofBH" target="_blank" rel="noreferrer">
          <img
            className="img-fluid"
            src="https://openflights.org/banner/PTK8ofBH.png"
            width="400"
            height="70"
            alt="OpenFlights"
          />
        </a>
        &nbsp;&nbsp;
        <a href="https://my.flightradar24.com/pkort" target="_blank" rel="noreferrer">
          <img
            className="img-fluid"
            src="/images/icons/misc/myfr24_70.png"
            width="296"
            height="70"
            alt="myFlightradar24"
          />
        </a>
        &nbsp;&nbsp;
        <a href="https://mtp.travel/users/48299" target="_blank" rel="noreferrer">
          <img
            className="img-fluid"
            src="/images/icons/misc/mtp_70.png"
            width="70"
            height="70"
            alt="Most Traveled People"
          />
        </a>
        &nbsp;&nbsp;
        <a href="https://nomadmania.com/profile/38359" target="_blank" rel="noreferrer">
          <img
            className="img-fluid"
            src="/images/icons/misc/nomadmania_70.png"
            width="70"
            height="70"
            alt="Nomad Mania"
          />
        </a>
      </div>
    </div>
  );
}
export default TravelMap;
